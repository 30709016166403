<template>
  <el-dialog
      v-model="$store.state.modal.viewRecordingModal"
      width="600px"
      :title="$t('Перегляд запису')"
      class="modal-custom"
  >
    <form v-if="task !== null">
      <div class="box-blue-modal modal-radio-blue">
        <div class="date-time-1">{{ getCurrentDay(task.taskdate) }}</div>
        <div class="drop-modal-box">
          <el-select
              v-model="task.status_id"
              style="width: 100%"
              size="large"
              filterable
          >
            <el-option
                v-for="item in status"
                :key="item.id"
                :label="item.label"
                :value="item.id"
            />
          </el-select>
        </div>
      </div>
      <div class="body-modal form-long">
        <div class="t-row">
          <div class="t-col">
            <div class="one-block-recording">
              <div class="caption">Дата призначення:</div>
              <el-date-picker
                v-model="task.taskdate"
                type="date"
                format="DD.MM.YYYY"
                value-format="YYYY-MM-DD"
                placeholder="Дата"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="t-col">
            <div class="one-block-recording box-mini-time">
              <div class="caption">Час:</div>
              <el-time-select
                placeholder="Початок прийому"
                v-model="task.beginoftheinterval"
                style="width: 120px; margin-right: 10px"
                :min-time="task.beginoftheinterval"
                step="00:05"
                start="08:00"
                end="20:00"/>
              <el-time-select
                placeholder="Кінець прийому"
                v-model="task.endoftheinterval"
                style="width: 120px;"
                :min-time="task.beginoftheinterval"
                step="00:05"
                start="08:00"
                end="20:00"
              />
            </div>
          </div>
        </div>
        <div class="t-row">
          <div class="t-col">
            <div class="one-block-recording">
              <div class="caption">{{$t('Пацієнт')}}:</div>
              <span>{{task.patientid ? task.patientid.fullname : 'не вказано'}} <img title="Застрахований" v-show="task.patientid !== null && task.patientid.has_insurance === 1" src="../../../assets/images/ic_security_24px.svg" width="18" style="top: 4px;position: relative" alt="Застрахований"></span>
            </div>
          </div>
          <div class="t-col" v-if="task.patientid !== null">
            <div class="one-block-recording">
              <div class="caption">{{$t('Мобільний номер телефону')}}:</div>
              <div class="input-wr">
                <input
                    v-model="task.patientid.mobilenumber"
                    v-maska="'+38 (###) ### ## ##'"
                    class="custom-input"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="t-row">
          <div class="t-col">
            <div class="one-block-recording">
              <div class="caption">{{$t('Лікар')}} *</div>
              <el-select
                  v-model="task.doctorid"
                  class="m-2"
                  size="large"
                  filterable
              >
                <el-option
                    v-for="item in doctors"
                    :key="item.id"
                    :label="item.fullname"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="t-col">
            <div class="one-block-recording">
              <div class="caption">{{$t('Кабінет')}} *</div>
              <el-select
                  v-model="task.roomid"
                  class="m-2"
                  size="large"
                  filterable
              >
                <el-option
                    v-for="item in rooms"
                    :key="item.id"
                    :label="item.number"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
<!--        <div class="one-block-recording">-->
<!--          <div class="caption">{{$t('Послуга')}}</div>-->
<!--          <div v-for="service in task.healthproc_id" :key="service.id"><strong>{{service.name}}</strong></div>-->
<!--        </div>-->
        <div class="one-block-recording">
          <div class="caption">{{$t('Опис роботи')}}:</div>
          <div class="input-wr">
            <textarea class="mailing-textarea mini-textarea" v-model="task.workdescription"></textarea>
          </div>
        </div>
        <div class="one-block-recording">
          <div class="caption">{{$t('Примітка')}}:</div>
          <textarea class="mailing-textarea mini-textarea" v-model="task.comment"></textarea>
        </div>
<!--        <ul class="radio-list other-radio">-->
<!--          <li class="custom-long-radio">-->
<!--            <label>-->
<!--              <input type="checkbox" v-model="noticed" name="successCheckbox">-->
<!--              <span>Попереджений про візит!</span>-->
<!--            </label>-->
<!--          </li>-->
<!--          <li class="custom-long-radio">-->
<!--            <label>-->
<!--              <input type="checkbox" v-model="factofvisit" name="successCheckbox">-->
<!--              <span>Візит відбувся</span>-->
<!--            </label>-->
<!--          </li>-->
<!--        </ul>-->
        <div class="time-w-r" v-for="(time, index) in task.timeList" :key="index"><span>{{time.event}}: </span>{{ time.time }} </div>
      </div>
      <div class="footer-modal flex-footer footer-center-m">
        <div class="btn-right-footer">
          <div class="btn-tooltip-footer">
            <ul class="bottom-btn pay-box-btn big-btn">
              <li class="tooltip-wrapper" v-if="task.patientid !== null">
                <router-link :to="'/patients/' + task.patientid.id + '/info'" class="full-flex-center brand-hover with-border"><i class="ico-07"></i></router-link>
                <div class="tooltip">{{$t('В картку пацієнта')}}</div>
              </li>
            </ul>
          </div>
          <button class="btn-grey2" type="button" @click="save"><i class="ico-19"></i> {{$t('Редагувати')}}</button>
          <button type="button" class="btn-red" @click="remove(task.id)"><i class="ico-20"></i> {{$t('Видалити запис')}}</button>
        </div>
      </div>
    </form>
  </el-dialog>

</template>

<script>
export default {
  data () {
    return {
      task: null,
      url: '/tasks',
      noticed: false,
      factofvisit: false,
      cancelTask: false,
      date: false,
      services: [],
      status: [],
      pickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  async created () {
    this.emitter.on('viewTask', this.viewTask)
    this.emitter.on('boardDate', this.boardDate)
    await this.$http.get('/healthproc/all')
      .then(result => {
        this.services = result.data.healthproc
        this.status = result.data.status
      })
      .catch(error => {
        console.log('[Log error]', error)
        alert('Помилка завантаження списку послуг')
      })
  },
  watch: {
    noticed: function (oldValue, newValue) {
      this.task.noticed = oldValue
    },
    factofvisit: function (oldValue, newValue) {
      this.task.factofvisit = oldValue
    },
    cancelTask: function (oldValue, newValue) {
      this.task.cancel = oldValue
    }
  },
  methods: {
    boardDate (date) {
      this.date = date
    },
    async viewTask (data) {
      this.task = data
      this.task.doctorid = Number(data.doctorid.id);
      this.noticed = this.getNoticed(this.task.noticed)
      this.factofvisit = this.getFactofvisit(this.task.factofvisit)
      this.cancelTask = this.getCancel(this.task.cancel)
      await this.$http.get('/money/item-task', {
        params: {
          id: this.task.id
        }
      })
        .then(result => {
          this.task.healthproc_id = result.data
        })
        .catch(error => {
          console.log('[Log error]', error)
        })
    },
    getFactofvisit (q) {
      if (q === '0') {
        return false
      }
      if (q === false) {
        return false
      }
      return true
    },
    getCancel (q) {
      if (q === '0') {
        return false
      }
      if (q === false) {
        return false
      }
      return true
    },
    getNoticed (q) {
      if (q === '0') {
        return false
      }
      if (q === false) {
        return false
      }
      return true
    },
    save () {
      this.$http.post(this.url + '/save', {
        id: this.task.id,
        factofvisit: this.factofvisit ? 1 : 0,
        noticed: this.noticed ? 1 : 0,
        cancel: this.cancelTask ? 1 : 0,
        date: this.date,
        beginoftheinterval: this.task.beginoftheinterval,
        endoftheinterval: this.task.endoftheinterval,
        workdescription: this.task.workdescription,
        comment: this.task.comment,
        taskdate: this.task.taskdate,
        doctorid: this.task.doctorid.id,
        roomid: this.task.roomid.id,
        phone: this.task.patientid.mobilenumber,
        status: this.task.status_id
      })
        .then(result => {
          this.$emit('updateListTask', result.data)
        })
        .catch(error => {
          this.$root.alerts('error', error.response.data)
        })
    },
    clear () {
      this.fullname = ''
      this.sex = 0
      this.birthday = new Date()
      this.discount = 0
      this.country = ''
      this.state = ''
      this.region = ''
      this.city = ''
      this.address = ''
      this.postalcode = ''
      this.mobilenumber = ''
      this.comment = ''
      this.villageorcity = 1
    },
    cancel () {
      this.$modal.hide('watchOrderModal')
      this.clear()
    },
    remove (id) {
      if (confirm('Видалити запис?')) {
        this.$http.delete(this.url + '/delete-task', {
          params: {
            id,
            date: this.date
          }
        }).then(result => {
          this.emitter.emit('updateSchedule', result.data)
          this.$modal.hide('viewRecordingModal')
        }).catch(error => {
          console.log('[Error]', error)
        })
      }
    },
    getCurrentDay (date) {
      let days = ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'Пятниця', 'Субота']
      let d = new Date(date)
      let dayName = days[d.getDay()]
      return dayName + ', ' + d.toLocaleDateString()
    }
  },
  filters: {
    timeFormat (value) {
      let time = value.split(':')
      return time[0] + ':' + time[1]
    }
  },
  props: {
    rooms: {
      default: Array
    },
    patients: {
      default: Array
    },
    doctors: {
      default: Array
    }
  },
}
</script>

<style scoped>

</style>
